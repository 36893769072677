

  
  .mainLB {
    width: 100%;
    background-color: #ffffff;
    -webkit-box-shadow: 0px 5px 15px 8px #e4e7fb;
    box-shadow: 0px 5px 15px 8px #e4e7fb;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 0.5rem;
  }
  
  #headerLB {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2.5rem 2rem;
  }
  

  
  #leaderboard {
    width: 100%;
    position: relative;
  }
  
  .tableLB {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;

    cursor: default;
  }
  
  .tableLB tr {
    transition: all 0.2s ease-in-out;
    border-radius: 0.2rem;
  }
  
  .tableLB tr:not(:first-child):hover {
    background-color: #fff;
    transform: scale(1.1);
    -webkit-box-shadow: 0px 5px 15px 8px #e4e7fb;
    box-shadow: 0px 5px 15px 8px #e4e7fb;
  }
  
  .tableLB tr:nth-child(odd) {
    background-color: #f9f9f9;
  }
  
  .tableLB tr:nth-child(1) {
    color: #fff;
  }
  
 .tableLB td {
    height: 5rem;
    

    padding: 1rem 2rem;
    position: relative;
  }
  
  .number {
    width: 1rem;
    font-size: 2.2rem;
    font-weight: bold;
    text-align: left;
  }
  
  .name {
    text-align: left;
    font-size: 1.2rem;
  }
  
  .points {
    font-weight: bold;
    font-size: 1.3rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  
  .points:first-child {
    width: 10rem;
  }
  
  .gold-medal {
    height: 3rem;
    margin-left: 1.5rem;
  }
  
  .ribbon1 {
    width: 100%;
    height: 6.5rem;
    top: -0.5rem;
    background-color: #5c5be5;
    position: absolute;
    left: -1rem;
    -webkit-box-shadow: 0px 15px 11px -6px #7a7a7d;
    box-shadow: 0px 15px 11px -6px #7a7a7d;
  }
  
  .ribbon::before {
    content: "";
    height: 1.5rem;
    width: 1.5rem;
    bottom: -0.8rem;
    left: 0.35rem;
    transform: rotate(45deg);
    background-color: #5c5be5;
    position: absolute;
    z-index: -1;
  }
  
  .ribbon::after {
    content: "";
    height: 1.5rem;
    width: 1.5rem;
    bottom: -0.8rem;
    right: 0.35rem;
    transform: rotate(45deg);
    background-color: #5c5be5;
    position: absolute;
    z-index: -1;
  }
  
  #buttons {
    width: 100%;
    margin-top: 3rem;
    display: flex;
    justify-content: center;
    gap: 2rem;
  }
  
  .exit {
    width: 11rem;
    height: 3rem;
    
    font-size: 1.3rem;
    text-transform: uppercase;
    color: #7e7f86;
    border: 0;
    background-color: #fff;
    border-radius: 2rem;
    cursor: pointer;
  }
  
  .exit:hover {
    border: 0.1rem solid #5c5be5;
  }
  
  .continue {
    width: 11rem;
    height: 3rem;
    
    font-size: 1.3rem;
    color: #fff;
    text-transform: uppercase;
    background-color: #5c5be5;
    border: 0;
    border-bottom: 0.2rem solid #3838b8;
    border-radius: 2rem;
    cursor: pointer;
  }
  
  .continue:active {
    border-bottom: 0;
  }
  
